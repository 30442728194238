import React from 'react';
import { Button } from '@material-ui/core';


const ButtoPrimary = ({label, handleButton, disabled}) => {
    return (
        <Button variant="contained" 
                color="primary" 
                className="primary-button m-t20 m-b20"
                onClick={handleButton}
                disabled={disabled}>
            {label}
        </Button>
    )
}

export default ButtoPrimary;