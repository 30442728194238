import React, { useEffect, useState } from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import Button from './ButtoPrimary';


const StepThree = ({handleCheckbox, handleFormStep}) => {

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    

    const listCormobility = [
        {
          checked: false,
          value: "Diabetes Mellitus",
          text: "Diabetes Mellitus"
        }, 
        {
          checked: false,
          value: "Enfermedades cardiovasculares",
          text: "Enfermedades cardiovasculares (Hipertensión arterial, etc)"
        }, 
        {
          checked: false,
          value: "Enfermedad broncopulmonar obstructiva crónica",
          text: "Enfermedad broncopulmonar obstructiva crónica (EBPOC)"
        }, 
        {
          checked: false,
          value: "Enfermedad renal crónica",
          text: "Enfermedad renal crónica"
        }, 
        {
          checked: false,
          value: "Enfermedades neurológicas",
          text: "Enfermedades neurológicas"
        }, 
        {
          checked: false,
          value: "Inmunodeficiencia",
          text: "Inmunodeficiencia (VIH, etc)"
        }, 
        {
          checked: false,
          value: "Cáncer",
          text: "Cáncer"
        }, 
        {
          checked: false,
          value: "Embarazo",
          text: "Embarazo"
        }, 
        {
          checked: false,
          value: "Post-parto",
          text: "Post-parto (3-6 meses)"
        }, 
        {
          checked: false,
          value: "",
          text: "Ninguna"
        }
    ];

    const [cormobilitys, setCormobilitys] = useState(listCormobility);


    const handleArray = (value, index, checked) => {
      cormobilitys[index]['value'] = value;
      cormobilitys[index]['checked'] = checked;
      if(value === 'Ninguna') {
        cormobilitys.map(c => {
          return  c.checked = false
        });
        cormobilitys[index]['value'] = value;
        cormobilitys[index]['checked'] = checked;
      } else {
        cormobilitys.map(c => {
          if(c.value === 'Ninguna'){
              delete c.value;
              c.checked = false
          }
          return c.checked;
        })
      }
      setCormobilitys(cormobilitys);
      handleCheckbox(value, 'cormobility');
    }



    return (
      <>
        <div className="header-form flex-center flex-col">
            <span className="sub-title-covid font-16 text-center">
                ¿Tienes síntomas de COVID-19?
            </span>
            <h2 className="title-covid font-30 text-center">
                Cuéntanos tu situación de salud y te recomendarémos qué hacer
            </h2>
        </div>
        <div className="flex flex-col content-form">
            <span className="label-covid font-16 m-t10">
                ¿Padeces o has padecido alguna de las siguientes condiciones médicas?
            </span>
            {
                cormobilitys.map((cormobility, i) => (
                    <FormControlLabel key={i} control={
                        <Checkbox 
                            value={String(cormobility.checked)}
                            onChange={() => handleArray(cormobility.value ? cormobility.value : 'Ninguna', i, !cormobility.checked)}
                            className={cormobility.checked ? 'checkBoxActive' : 'checkBox'}
                            checked={cormobility.checked  ? true : false}
                        />
                        } 
                        label={<span className="color-gray">{cormobility.text}</span>} 
                    />
                ))
            }
        </div>
        <div className="flex-center">
          <Button label="Siguiente" handleButton={ () => handleFormStep(4)}/>
        </div>
      </>
    )
};

export default StepThree;