import { ADD_ERROR, REMOVE_ERROR } from '../actionTypes';

export default (state = {}, action) => {
    switch(action.type){
        case ADD_ERROR:
            return {
                ...state, 
                message: action.payload.message,
            };
        case REMOVE_ERROR:
            return {  state };
        default:
            return state;
    }
};