import {
    createStore,
    applyMiddleware,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from "redux-thunk";
import rootReducer from './reducers';



export default function configureStore(initialStore) { 
    const store = createStore(
      rootReducer,
      initialStore,
      composeWithDevTools(applyMiddleware(thunk))
    );
    return store;
}
