import React, { useEffect } from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core'
import variables from '../../../theme/_variables.scss';
import { withStyles } from '@material-ui/core/styles';
import Button from './ButtoPrimary';

const styles = themes => ({
    root: {
      color: `${variables.primaryColor} !important`,
      '&$checked': {
            color: `${variables.primaryColor} !important`,
        },
    },
    checked: {},
});


const StepOne = ({handleCheckbox, handleFormStep, classes}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const symptomsVirus = [
        {
            value: "fiebre",
            text: "Fiebre (temperatura mayor a 38.5 ºC)"
        }, 
        {
            value: "tos seca",
            text: "Tos seca"
        }, 
        {
            value: "Dificultad respiratoria",
            text: "Dificultad para respirar"
        }, 
    ]
  
    const othersSymptomsVirus = [
        {
            value: "perdida de olfato",
            text: "Perdida de olfato"
        },
        {
            value: "perdida de gusto",
            text: "Perdida de gusto"
        },
        {
            value: "tos productiva",
            text: "Tos productiva (con expectoración)"
        }, 
        {
            value: "cansancio",
            text: "Cansancio"
        }, 
        {
            value: "dolor de garganta",
            text: "Dolor de garganta"
        }, 
        {
            value: "diarrea",
            text: "Diarrea"
        }, 
        {
            value: "vomitos",
            text: "Vómitos"
        }
    ]
    
    return (
        <>
            <div className="header-form flex-center flex-col">
                <span className="sub-title-covid font-16 text-center">
                    ¿Tienes síntomas de COVID-19?
                </span>
                <h2 className="title-covid font-30 text-center">
                    Cuéntanos tu situación de salud y te recomendarémos qué hacer
                </h2>
            </div>
            <div className="flex flex-col content-form">

                <span className="label-covid m-t10">
                    ¿Has presentado alguno de los siguientes síntomas?
                </span>
                {
                    symptomsVirus.map((symptoms, i) => (
                        <FormControlLabel key={i} control={
                            <Checkbox 
                                value={symptoms.value}
                                onChange={() => handleCheckbox(symptoms.value, 'symptomsVirus')}
                                classes={{
                                    root: classes.root,
                                    checked: classes.checked,
                                }}
                            />
                            } 
                            label={<span className="color-gray">{symptoms.text}</span>} 
                        />
                    ))
                }
                <span className="label-covid font-16 poppins m-t60">
                    ¿Has presentado otros síntomas?
                </span>
                {
                    othersSymptomsVirus.map((otherSymptoms, i) => (
                        <FormControlLabel key={i} control={
                            <Checkbox 
                                value={otherSymptoms.value}
                                onChange={() => handleCheckbox(otherSymptoms.value, 'othersSymptomsVirus')}
                                classes={{
                                    root: classes.root,
                                    checked: classes.checked,
                                }}
                            />
                            } 
                            label={<span className="color-gray">{otherSymptoms.text}</span>} 
                        />
                    ))
                }
            </div>
            <div className="flex-center flex-col">
                <Button label="Siguiente" handleButton={ () => handleFormStep(2)}/>
            </div>
        </>
    )
};

export default withStyles(styles)(StepOne);