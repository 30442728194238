import React, { useEffect, useState } from 'react';
//analytics
//import ReactGA from 'react-ga';
//ReactTelInput 
import ReactTelInput from 'react-telephone-input/lib/withStyles';
import Flags from '../../../assets/img/flags.png';
import Button from './ButtoPrimary';



const TestResult = ({ results, handlePhone, handleSubmitContact, handleFormStep, disabledButton }) => {
    const [contact, setContact] = useState(false),
          [disabled, setDisabled ] = useState(disabledButton);

    
    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

    useEffect(() => {
        if(!results.successContact && (results.health_risk === 'Moderado' || results.health_risk === 'Severo')){
            setDisabled(true);
        } else if(disabledButton) {
            setDisabled(disabledButton)
        } else {
            setDisabled(false);
        }

    }, [results, disabledButton]);
    
    return (
        <>
            <div className="test-result flex flex-col container m-t20">
                <p className="flex-full  m-t20">
                    Este es un sistema automatizado de evaluación de acuerdo a las respuesta del usuario, esta evaluación 
                    es unicamente como guia y no reemplaza una evaluación fisica ni reemplaza un contacto con las 
                    autoridades competentes. Las recomendaciones proporcionadas son las utilizadas en protocolos mundiales 
                    en materia de COVID-19. Sigue primordialmente las recomendaciones e indicaciones del Ministerio de 
                    Salud de Panamá y sus protocolos.
                </p>
                <div className="content-infection-risk">
                    <div className="flex">
                        <div className="flex-col flex-cw-4">
                            <span className="sub-title-covid font-16">
                                {results.message}
                            </span>
                            <span className="flex sub-title-risk font-16">
                                Riesgo de salud (basado en síntomas):
                            </span>
                        </div>
                        <div className="flex-row flex-cw-0.6">
                            {results.health_risk === 'Leve' && 
                                <span className="result-box-low flex jc-c m-t20">
                                    <span className="title low font-16 text-center align-ic">
                                        {results.health_risk}
                                    </span>
                                </span>
                            }
                            {results.health_risk === 'Moderado' && 
                                <span className="result-box-medium flex jc-c m-t20">
                                    <span className="title medium font-16 text-center align-ic">
                                        {results.health_risk}
                                    </span>
                                </span>
                            }
                            {results.health_risk === 'Severo' && 
                                <span className="result-box-hight flex jc-c m-t20">
                                    <span className="title hight font-16  text-center align-ic">
                                        {results.health_risk}
                                    </span>
                                </span>
                            }
                            {results.health_risk === 'No tiene' && 
                                <span className="result-box-not-risk flex jc-c m-t20">
                                    <span className="title not-risk font-16 text-center align-ic">
                                        {results.health_risk}
                                    </span>
                                </span>
                            }
                        </div>
                    </div>
                    <span className="flex color-gray m-t10 f-11em">Recomendaciones:</span>
                    {results.severe_health_risk_title &&
                        <ul className={`color-gray m-t10 ${results.severe_health_risk_title && 'p-l15'}`}>
                            <li>- {results.severe_health_risk_title}</li>
                        </ul>
                    }
                    {results.steps && 
                        <ul className="color-gray m-t10 m-b10">
                            {results.steps.map((step, i) => (
                                <li key={i}>• {step}</li>
                            ))}
                        </ul>
                    }
                    {!results.successContact &&
                        <>
                            {(results.health_risk === 'Severo' || results.health_risk === 'Moderado') && contact === false &&
                                <div className="flex-full m-t10 m-b10">
                                    <Button label="Acepto que me contacten" 
                                                handleButton={ () => setContact(true)} />
                                </div>
                            }
                            {contact === true &&
                                <div className="flex flex-col m-t10">
                                    <span className="flex color-gray m-t10">
                                        Para que podamos contactarte debes ingresar tu telefono:
                                    </span>
                                    <div className="m-t25 mx-400">
                                        <ReactTelInput required={true}
                                                    onChange={(telNumber) => handlePhone('cell_phone', telNumber)}
                                                    value={results.cell_phone || '+507'}
                                                    defaultCountry="pa"
                                                    flagsImagePath={Flags}
                                                    className={(!results.cell_phone) ? 'telInput' : "telInput"} />
                                    </div>
                                    <div className="flex-full m-t10 m-b10">
                                        <Button label="Enviar" 
                                                handleButton={ () => handleSubmitContact()} 
                                                disabled={(!results.cell_phone) ? true : false}/>
                                    </div>
                                </div>
                            }
                        </>
                    }
                </div>
                <div className="content-infection-risk m-t15 m-b20">
                    <div className="flex">
                        <div className="flex-col flex-cw-4">
                            <span className="sub-title-covid font-16">
                                {results.message}
                            </span>
                            <span className="flex sub-title-risk font-16">
                                Riesgo epidemiológico (estar contagiado):
                            </span>
                        </div>
                        <div className="flex-row flex-cw-0.6">
                            {results.risk_of_infection === 'Bajo' && 
                                <span className="result-box-low flex jc-c m-t20">
                                    <span className="title low font-16 text-center align-ic">
                                        {results.risk_of_infection}
                                    </span>
                                </span>
                            }
                            {results.risk_of_infection === 'Medio' && 
                                <span className="result-box-medium flex jc-c m-t20">
                                    <span className="title medium font-16 text-center align-ic">
                                        {results.risk_of_infection}
                                    </span>
                                </span>
                            }
                            {results.risk_of_infection === 'Alto' && 
                                <span className="result-box-hight flex jc-c m-t20">
                                    <span className="title hight font-16 text-center align-ic">
                                        {results.risk_of_infection}
                                    </span>
                                </span>
                            }
                        </div>
                    </div>
                    <span className="flex color-gray m-t10 f-11em">Recomendaciones:</span>
                    {results.steps_risk_infection && 
                        <ul className="color-gray m-t10 m-b10">
                            {results.steps_risk_infection.map((step, i) => (
                                <li key={i}>• {step}</li>
                            ))}
                        </ul>
                    }
                </div>
            </div>
            <div className="flex-center">
                <Button label="Ir al inicio" handleButton={ () => window.location.replace("https://www.semmpanama.com/")} disabled={disabled}/>
            </div>
        </>
    )
};

export default TestResult;