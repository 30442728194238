import { combineReducers } from 'redux';
import covid19Control from './covid19Control';
import loadingControl from './loadingControl';
import errorsControl from './errorsControl';

const rootReducer = combineReducers({
   covid19Control,
   loadingControl,
   errorsControl
});

export default rootReducer;