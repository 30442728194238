import React from 'react';
import '../theme/styles.scss';
import { BrowserRouter,  Switch} from "react-router-dom";
import { useRoutes } from '../hocks/index';
import Covid19Pages from './covid/Covid19Pages';
import NoFound from './404/404';

const App = ({query}) =>  {
  const Covid19 = useRoutes('/', {}, Covid19Pages, query);
  const Covid19StepTwo = useRoutes('/preguntas-2', {}, Covid19Pages, query);
  const Covid19StepThree = useRoutes('/preguntas-3', {}, Covid19Pages, query);
  const Covid19StepFour = useRoutes('/preguntas-4', {}, Covid19Pages, query);
  const Covid19Results = useRoutes('/resultados', {}, Covid19Pages, query);
  const Error404 = useRoutes('', {}, NoFound, query);
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          {Covid19}
          {Covid19StepTwo}
          {Covid19StepThree}
          {Covid19StepFour}
          {Covid19Results}
          {Error404}
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
