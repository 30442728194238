import React, {useEffect, useState} from 'react';
import { MenuItem, TextField } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Terms from '../../../components/Terms';
import Button from './ButtoPrimary';


const FormStepFour = ({handleInput, handleCheckTerms, formErrors, values, handleFormStep, disabledButton, handleSubmit}) => {

    const [ranks, setRanks] = useState([]),
          [disabled, setDisabled] = useState(disabledButton),
          [openDialog, setOpenDialog] = useState(false);
    

    const rangeAge = () => {
        let ranks = [];
        for (let index = 1; index <= 99 && index > 0; index++) {
                ranks.push(index);
        }
        setRanks(ranks);
    }

    const handleOpenDialog = () => setOpenDialog(!openDialog);

    const handleButton = async () => {
        if(!values.agree_policy_privacy) {
            handleOpenDialog();
        } else {
            await handleSubmit();
            await handleFormStep(5);
        }
    }
    
    
    useEffect(() => {
        window.scrollTo(0, 0);
        rangeAge();
    }, []);

    useEffect(() => {
        if(values.full_name === '' 
        || values.dni === '' 
        || values.age === 'default'
        || values.sex === 'default'
        || values.email === ''
        || values.country === 'default'
        || values.city === '') {
            setDisabled(true);
        } else if(disabledButton) {
            setDisabled(disabledButton)
        } else {
            setDisabled(false);
        }

    }, [values, disabledButton]);
    
    return (
        <>
            <div className="header-form flex-center flex-col">
                <span className="sub-title-covid font-16 text-center">
                    ¿Tienes síntomas de COVID-19?
                </span>
                <h2 className="title-covid font-30 text-center">
                    Para poder ayudarte necesitamos saber algunos  datos sobre ti
                </h2>
            </div>
            <div className="flex flex-col content-form">
                <TextField name="full_name"
                        type="text"
                        placeholder="Nombre y Apellido"
                        variant="outlined"
                        error={true}
                        helperText={formErrors.full_name}
                        className="m-t25"
                        InputLabelProps={{
                            classes: {
                                root: 'cssLabelSuccess',
                                },
                                shrink: true,
                            }}
                            InputProps={{
                                classes: {
                                    notchedOutline: 'cssInputSuccess',
                                    root: !formErrors
                                        ? "cssInput"
                                        : !!formErrors.full_name
                                            ? "cssInputError"
                                            : "cssInputSuccess",
                                }, 
                            }}
                            margin="normal"
                            value={values.full_name} 
                            required={true}
                            onChange={(event) => handleInput(event)}
                />
                <TextField name="dni"
                        type="text"
                        placeholder="Cédula"
                        variant="outlined"
                        error={true}
                        helperText={formErrors.dni}
                        className="m-t25"
                        InputLabelProps={{
                                classes: {
                                    root: 'cssLabelSuccess',
                                },
                                shrink: true,
                        }}
                        InputProps={{
                                classes: {
                                    notchedOutline: 'cssInputSuccess',
                                    root: !formErrors
                                        ? "cssInput"
                                        : !!formErrors.dni
                                            ? "cssInputError"
                                            : "cssInputSuccess",
                                }, 
                            }}
                            margin="normal"
                            value={values.dni} 
                            onChange={(event) => handleInput(event)}
                />
                <TextField  select
                            name="age"
                            value={values.age}
                            helperText={formErrors.age && formErrors.age}
                            required={true}
                            onChange={(event) => handleInput(event)}
                            variant="outlined"
                            className={`m-t20 color-gray ${values.age === 'default' && 'default'}`}
                            InputProps={{
                                classes: {
                                    notchedOutline: 'cssInputSelect',
                                }, 
                            }}
                            
                            SelectProps={{
                                IconComponent: KeyboardArrowDownIcon,
                                classes: {
                                    root: !formErrors
                                        ? "cssInput"
                                        : !!formErrors.age
                                            ? "cssInputError"
                                            : "cssInputSuccess",
                                }
                            }}
                >
                    <MenuItem value="default" disabled hidden>Selecciona la edad</MenuItem>
                    {
                        ranks.map(item => (
                            <MenuItem key={item} value={item}>
                                {item === 1 ? '0 - 1 año' : `${item} años`}
                            </MenuItem>
                        ))
                    }
                </TextField>
                <TextField  select
                            name="sex"
                            value={values.sex}
                            helperText={formErrors.sex && formErrors.sex}
                            required={true}
                            onChange={(event) => handleInput(event)}
                            variant="outlined"
                            className={`m-t20 color-gray ${values.sex === 'default' && 'default'}`}
                            InputProps={{
                                classes: {
                                    notchedOutline: 'cssInputSelect',
                                }, 
                            }}
                            SelectProps={{
                                IconComponent: KeyboardArrowDownIcon,
                                classes: {
                                    root: !formErrors
                                        ? "cssInput"
                                        : !!formErrors.sex
                                            ? "cssInputError"
                                            : "cssInputSuccess",
                                }
                            }}
                >
                    <MenuItem value="default" disabled hidden>Selecciona el sexo</MenuItem>
                    <MenuItem value="F">Femenino</MenuItem>
                    <MenuItem value="M">Masculino</MenuItem>
                </TextField>
                <TextField name="email"
                        value={values.email}
                        placeholder="Correo electrónico"
                        type="text"
                        variant="outlined"
                        error={true}
                        helperText={formErrors.email}
                        className="m-t25"
                        InputLabelProps={{
                            classes: {
                            root: 'cssLabelSuccess',
                            },
                            shrink: true,
                        }}
                        InputProps={{
                            classes: {
                                notchedOutline: 'cssInputSuccess',
                                root: !formErrors
                                    ? "cssInput"
                                    : !!formErrors.email
                                        ? "cssInputError"
                                        : "cssInputSuccess",
                            }, 
                            }}
                        margin="normal"
                        required={true}
                        onChange={(e) => handleInput(e)}
                />
                <TextField  select
                            name="country"
                            value={values.country}
                            helperText={formErrors.country && formErrors.country}
                            required={true}
                            onChange={(event) => handleInput(event)}
                            variant="outlined"
                            className={`m-t20 color-gray ${values.country === 'default' && 'default'}`}
                            InputProps={{
                                classes: {
                                    notchedOutline: 'cssInputSelect',
                                }, 
                            }}
                            SelectProps={{
                                IconComponent: KeyboardArrowDownIcon,
                                classes: {
                                    root: !formErrors
                                        ? "cssInput"
                                        : !!formErrors.country
                                            ? "cssInputError"
                                            : "cssInputSuccess",
                                }
                            }}
                >
                    <MenuItem value="default" disabled>Selecciona el país</MenuItem>
                    <MenuItem value="PA">Panamá</MenuItem>  
                </TextField>
                <TextField name="city"
                        value={values.city}
                        required={true}
                        placeholder="Indique la ciudad"
                        type="text"
                        variant="outlined"
                        error={true}
                        helperText={formErrors.city}
                        className="color-gray m-t25"
                        InputLabelProps={{
                            classes: {
                                root: 'cssLabelSuccess',
                            },
                            shrink: true,
                            }}
                        InputProps={{
                            classes: {
                                notchedOutline: 'cssInputSuccess',
                                root: !formErrors
                                    ? "cssInput"
                                    : !!formErrors.city
                                        ? "cssInputError"
                                        : "cssInputSuccess",
                            }, 
                            }}
                        margin="normal"
                        onChange={(event) => handleInput(event)}
                />
                {openDialog === true &&
                    <Terms handleClose={handleOpenDialog}
                        handleCheckTerms={handleCheckTerms}
                        value={values.agree_policy_privacy}
                    />
                }
            </div>
            <div className="flex-center">
                <Button disabled={disabled} label="Finalizar" handleButton={ () => handleButton()}/>
            </div>
        </>
    )
};

export default FormStepFour;