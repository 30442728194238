import { COVID_19, CONTACT_COVID19, CLEAN_RESULT_COVID } from '../actionTypes';

const DEFAULT_STATE = {
    message: '',
    steps: [],
    health_risk: '',
    risk_of_infection: '',
    steps_risk_infection: [],
    severe_health_risk_title: '',
    personId: '',
    successContact: false,
    messageAlert: ''
}

export default  (state = DEFAULT_STATE, action) => {
    switch(action.type){
        case COVID_19:
            return {
                ...state,
                message: action.payload.message,
                steps: action.payload.steps,
                health_risk: action.payload.health_risk,
                risk_of_infection: action.payload.risk_of_infection,
                steps_risk_infection: action.payload.steps_risk_infection,
                severe_health_risk_title: action.payload.severe_health_risk_title,
                personId: action.payload.personId,
                successContact: action.payload.successContact,
                agree_policy_privacy: action.payload.agree_policy_privacy
            };
        case CONTACT_COVID19:
            return {
                ...state,
                successContact: action.payload.successContact,
                messageAlert: action.payload.messageAlert,
            };
        case CLEAN_RESULT_COVID: 
            return { state }
        default:
            return state;
    }
};