import React  from 'react';
import { Route, Redirect} from "react-router-dom";


export const useRoutes = (path, permissions={}, Component, query) => {
    if(Object.keys(permissions).length > 0) {
        return(
            <Route exact 
                   path={path} 
                   render={(props) => (
                       permissions.isLogged === true && 
                       (permissions.isAdmin === true 
                        || permissions.isCoordinator === true 
                        || permissions.isSuper === true
                       ) 
                       ? <Component {...props} {...query} />
                       : <Redirect to='/' />
                   )} 
            />
        )
    } else {
        return (
            <Route exact 
                   path={path}
                   render={(props) => (
                        <Component {...props} {...query} />
                   )} 
            />
        )
    }
}