import React, { useEffect } from 'react';
//EXTERIOR COMPONENTS
import Favicon from 'react-favicon';
import FavIcon from '../../assets/img/favicon/semm/favicon.png';
import SentimentDissatisfiedRoundedIcon from '@material-ui/icons/SentimentDissatisfiedRounded';
import Button from '../covid/components/ButtoPrimary';
//styles
import './404.scss';
//COMPONENTS
import Navbar from '../../components/Navbar';


const Error404  = (props) => {

    useEffect(() => {
        document.title = 'SEMM | NO FOUND';
    }, [])
    
    return (
        <>
            <Navbar />
            <Favicon url={FavIcon}/>
            <div className="p-v48 flex-full h-80vh container">
                <div className="flex jc-c flex-col m-l20">
                    <SentimentDissatisfiedRoundedIcon className="icon"/>
                    <h1 className="text-center text-shadow light font-50">404</h1>
                    <p className="nm strong font-16">
                        Lo sentimos, no se encontró la página que estas buscando.
                    </p>
                    <div className="flex-center flex-col">
                        <Button type="submit" label="Volver al inicio" handleButton={ () => props.history.push('/')}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Error404;