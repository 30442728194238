import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';


export default function CircularIndeterminate() {
  return (
    <div className="spinner-container">
      <CircularProgress className="circle"/>
      <span className="text-spinner ">Su solicitud esta siendo procesada...</span>
    </div>
  );
}