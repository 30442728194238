import { ADD_ERROR, REMOVE_ERROR } from "../actionTypes";

export const addError = error => ({
  type: ADD_ERROR,
  payload: { message: error }
});

export const removeError = () => ({
  type: REMOVE_ERROR,
  payload: {}
});


export const error = error => {
  return dispatch => {
    dispatch(addError(error));
  };
}

export const reset = () => dispatch => dispatch(removeError());
