import { REMOVE_OR_ACTIVE_LOADING } from "../actionTypes";

export const LoadingStatus = (isLoading) => ({
  type: REMOVE_OR_ACTIVE_LOADING,
  payload: { isLoading }
});




export const reset = (isLoading) => dispatch => dispatch(LoadingStatus(isLoading));