import React from 'react';
import {  
    FormControlLabel,
    Radio,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Button from '../pages/covid/components/ButtoPrimary';

const ScrollDialog = ({handleCheckTerms, value, handleClose}) =>  {

  return (
    <div>
        <Dialog open={true}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
        >
            <div className="flex jc-b">
                <DialogTitle id="scroll-dialog-title">Políticas de Privacidad</DialogTitle>
                <CloseIcon className="m-t20 m-r40" onClick={() => {
                    handleClose();
                    handleCheckTerms(false);
                }}/>
            </div>
            <DialogContent dividers={true}>
                <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                    className="font-12 color-black text-justify"
                >
                    A excepción de lo establecido más adelante, toda información que relacione su identidad con las 
                    condiciones físicas y mentales presentes, pasadas o futuras y su historia médica, es considerada 
                    información de carácter privado y tratada como tal. SEMM y CONTACTAMED PANAMÁ, S.A., garantizan la 
                    confidencialidad médico(“Profesional de la salud”) - paciente (“Afiliado o Usuario”) que exige las 
                    leyes de la República de Panamá. Como parte de su proceso operativo CONTACTAMED PANAMÁ, S.A., 
                    registra su información personal y se obliga a no venderla, licenciarla o compartirla fuera de SEMM 
                    o CONTACTAMED PANAMÁ, S.A., y sus entidades asociadas excepto: (i) si Usted autoriza expresamente a 
                    hacerlo, (ii) si es necesario para permitir a nuestros profesionales de servicios médicos, entidades 
                    asociadas o agentes, proveerle servicios en nuestro nombre, (iii) solo con el fin de proveerle 
                    nuestros productos o servicios o aquellos de nuestras entidades asociadas, (iv) solo para 
                    suministrarla a entidades que realicen servicios de mercadeo y promoción para nosotros o en 
                    nuestro nombre, o a entidades con las cuales tengamos alianzas de mercadeo, (v) cuando sea   
                    necesario con motivo de la venta de la totalidad o una mayoría sustancial de los activos de 
                    SEMM o CONTACTAMED PANAMÁ, S.A., o su fusión o reorganización con otra entidad, (vi) 
                    cuando sea requerido o permitido por la Ley,(vii) cuando Usted sea un paciente o usuario que 
                    accede a nuestro servicio o plataforma a través convenios suscritos entre SEMM o 
                    CONTACTAMED PANAMÁ,S.A., y compañías aseguradoras, administradoras de salud o similares. 
                    A estos efectos, se considera como información personal, los nombres y apellidos, la edad,la 
                    afección o dolencia sufrida, la medicación recetada, el tiempo de recuperación, el 
                    correo electrónico y los teléfonos de contacto del Afiliado o Usuario y sus dependientes. 
                    SEMM y CONTACTAMED PANAMÁ, S.A., se reservan el derecho de utilizar data anónima proveniente  
                    de informaciones generadas y almacenadas durante el proceso de interacción  entre El Afiliado o 
                    Usuario, sus dependientes y el “Profesional de la salud” con el fin de proveer información 
                    estadística a terceros. Con la aceptación de estos términos y condiciones de uso, todo Afiliado 
                    o usuario que haya adquirido el derecho de ingresar a esta plataforma a través de 
                    compañías aseguradoras, administradoras de salud o similares, aceptan y autorizan expresamente a 
                    SEMM y CONTACTAMED PANAMÁ, S.A., y a los médicos tratantes a entregar a estas empresas la 
                    información médica que estas requieran, aun cuando la misma esté directamente relacionada con la 
                    afección o dolencia sufrida, el tratamiento recetado, el tiempo de recuperación y cualquier 
                    otra información que esté directamente relacionada con la consulta realizada a través de esta 
                    plataforma.
                </DialogContentText>
            </DialogContent>
            <DialogActions className="flex-full">
                <FormControlLabel label={
                    <span className="font-16 flex-center fw color-gray">
                        Acepto las
                        <p className="m-l5 strong">
                            Políticas de Privacidad
                        </p>
                    </span>
                }
                control={
                    <Radio checked={value}
                           onClick={() => handleCheckTerms(value === true ? false : true)}
                           name="agree_policy_privacy"
                           className={value === true ? 'checkBox' : 'checkBoxActive'}
                           value={value}
                           inputProps={{ 'aria-label': 'f-1em' }}
                    />
                }
                />
                <Button label="Entendido y acepto" 
                        handleButton={ () => handleClose()}
                        disabled={value === false ? true : false}/>
            </DialogActions>
        </Dialog>
    </div>
  );
}

export default ScrollDialog;