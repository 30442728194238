import { REMOVE_OR_ACTIVE_LOADING} from "../actionTypes";

const messages = (state = {loading: false}, action) => {
  switch (action.type) {
    case REMOVE_OR_ACTIVE_LOADING:
      return {...state, loading: action.payload.isLoading };
    default:
      return state;
  }
};

export default messages;