import React from 'react';
import { NavLink, withRouter } from "react-router-dom";
import { AppBar, Toolbar, } from '@material-ui/core';
import LogoSemm from '../assets/img/logos/semm/logo.png';


const Navbar = ({logo, insurance, bgColor}) => {
    return (
        <AppBar position="static">
            <Toolbar className={`flex jc-b ${bgColor}`} variant="dense">
                <NavLink to="/">
                    <img alt={insurance} src={logo} className="logo"/>
                </NavLink>
            </Toolbar>
      </AppBar>
    )
}


Navbar.defaultProps = {
    logo: LogoSemm,
    insurance: 'SEMM',
    bgColor: 'bg-color-white'
};


export default withRouter(Navbar);