
const ValidatorCovid = (field, value, setFormErrors, handleDisabled) => {
    let error  = {};
    let validate = false;

    switch (field) {
      case 'full_name':
        validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{3,})$/.test(value);
        (!validate) ? error.full_name = "Nombres inválidos." : delete error.full_name;
        break;
      case 'dni':
        validate = /^([a-zA-Z0-9_-]{5,})$/.test(value);
        (!validate) ? error.dni = "Debe colocar un número de documento válido." : delete error.dni;
        break;
      case 'cell_phone':
        validate = /^([0-9()+ -.]{8,20})$/.test(value);
        (!validate) ? error.cell_phone = "Teléfono inválido. Ejemplo: +58 412-7654321" : delete error.cell_phone;
        break;
      case 'age':
        validate = value !== '';
        (!validate) ? error.age = "Debe seleccionar la edad." : delete error.age;
        break;
      case 'sex':
        validate = /^([FM]{1})$/.test(value) && value !== 'default';
        (!validate) ? error.sex = "Debe seleccionar el sexo." : delete error.sex;
          break;
      case 'email':
        validate = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(value);
        (!validate) ? error.email = "Correo electrónico inválido." : delete error.email;
        break;
      case 'country':
        validate = value !== '' && value !== 'default';
        (!validate) ? error.country = "Debe seleccionar un país válido." : delete error.country;
      break;
      case 'city':
        validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]{2,})$/.test(value);
        (!validate) ? error.city = "Debe seleccionar una ciudad válida." : delete error.city;
        break;
      case 'traveled':
          validate = value !== null || value !== '';
          (!validate) ? error.traveled = "Debe indicar si ha viajado fuera del país en los últimos 15 días." : delete error.traveled;
        break;
      case 'contact_infected':
          validate = value !== null || value !== '';
          (!validate) ? error.contact_infected = "Debe indicar si ha tenido contacto con alguna persona infectada en los últimos 15 días." : delete error.contact_infected;
        break;
      default:
        break;
    }
    setFormErrors(error);
    handleDisabled(Object.keys(error).length > 0 ? true : false);
};

export default ValidatorCovid;
