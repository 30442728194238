import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
//COMPONENTS
import Navbar from '../../components/Navbar';
import SnackBar from '../../components/SnackBar';
import Spinner from '../../components/Spinner';
import StepOne from './components/StepOne';
import StepTwo from './components/StepTwo';
import StepThree from './components/StepThree';
import StepFour from './components/StepFour';
import TestResult from './components/TestResult';
//EXTERIOR COMPONENTS
import Favicon from 'react-favicon';
import FavIcon from '../../assets/img/favicon/semm/favicon.png';
//styles
import './covid19.scss';
//utils
import ValidatorCovid from '../../utils/ValidatorCovid';
//apis
import { requestCovid, requestContactCovid } from '../../store/actions/covid19';



const Covid19Page = (props) => {
    const defaultState =  {
        age: 'default',
        sex: 'default',
        country: 'default',
        city: '',
        email: '',
        full_name: '',
        cell_phone: '',
        dni: '',
        symptomsVirus: [],
        othersSymptomsVirus: [],
        cormobility: [],
        contact_infected: '',
        works_in_health: false,
        traveled: '',
        ranks: [],
        formErrors: [],
        messageAlert: props.covidResponse.messageAlert,
        agree_policy_privacy: false,
    }

    const [formStep, setFomStep] = useState(1), 
          [covidValues, setCovidValues] = useState(defaultState), 
          [formErrors, setFormErrors]  = useState([]),
          [disabledButton, setDisabledButton] = useState(false),
          [openAlert, setOpenAlert] = useState(false);


    useEffect(() => {
      window.scrollTo(0, 0);
      document.title = `SEMM | COVID-19 | ${formStep !== 5 ? `Preguntas ${formStep}`  : 'Resultados'}`;

      if (formStep === 1 && props.history.location.pathname !== '/') {
        props.history.push('/');
      } else if (formStep !== 1 
        && formStep !== 5
        && (props.history.location.pathname === '/' || props.history.location.pathname !== '/')) {
        props.history.push(`/preguntas-${formStep}`);
      } else if(formStep === 5) {
        props.history.push('/resultados');
      }
    }, [formStep, props.history.location.pathname]);

    const handleFormStep = (step) => {
      setFomStep(step);
      if(step === 1) {
        props.history.push('/');
      } else if(step === 5) {
        props.history.push('/resultados');
      } else {
        props.history.push(`/preguntas-${step}`);
      }
    };

    const handleInputGlobal = (event) => {
        let { name, value } = event.target;
        ValidatorCovid(name, value, setFormErrors, setDisabledButton);
        setCovidValues({...covidValues, [name]: value});
    }

    const handlePhone = (name, value) => {
      ValidatorCovid(name, value, setFormErrors, setDisabledButton);
      setCovidValues({...covidValues, [name]: value});
    }

    const handleChexkboxStep = (value, type) => {
      if (type === 'symptomsVirus') {
        const response = handleArray(value, covidValues.symptomsVirus);
        setCovidValues({...covidValues, symptomsVirus: response});
      } else if (type === 'othersSymptomsVirus') {
        const response = handleArray(value, covidValues.othersSymptomsVirus);
        setCovidValues({...covidValues, othersSymptomsVirus: response});
      } else if (type === 'cormobility') {
        const response = handleArray(value, covidValues.cormobility);
        setCovidValues({...covidValues, cormobility: response});
      }
    }  

    const handleArray = (value, array) => {
      const result = array.find(data => data === value);
      if (result) {
        const index = array.indexOf(result);
        if (index > -1) {
          array.splice(index, 1);
        }
      } else {
        array.push(value);
      }
      return array;
    }

    const handleRadio = (event, name) => {
      let { value } = event.target;
      if(name === 'traveled') {
        if(value === 'Si') {
          value = true
        }  
        else{
          value = false 
        } 
      }
      ValidatorCovid(name, value, setFormErrors, setDisabledButton);
      setCovidValues({...covidValues, [name]: value});
    };
    
    const handleSelect = (value) => setCovidValues({...covidValues, works_in_health: value});

    const handleCheckTerms = (value) => {
      setCovidValues({...covidValues, agree_policy_privacy: value});
    }
  

    const handleSubmit = async () => {
      const empty = covidValues.cormobility.find(r => r === "Ninguna");
      let cormobility_send = empty ? [] : covidValues.cormobility;
      const {
          full_name,
          cell_phone,
          dni,
          age,
          sex,
          country,
          city,
          symptomsVirus,
          othersSymptomsVirus,
          contact_infected,
          works_in_health,
          traveled,
          email   
      } = covidValues;

      const data = {
          full_name,
          cell_phone,
          dni,
          age,
          sex,
          country,
          city,
          cormobility: cormobility_send,
          symptomsVirus,
          othersSymptomsVirus,
          contact_infected,
          works_in_health,
          traveled,
          email,
          origin: 'SEMM PANAMA',
      }
     await props.requestCovid(data);
    }

    const handleSendData = async () => {
      const { full_name, cell_phone, dni } = covidValues;
      if(full_name && cell_phone && !formErrors.full_name && !formErrors.cell_phone && !formErrors.dni) {
        await props.requestContactCovid({ full_name, cell_phone, dni, personId: props.covidResponse.personId });
        setOpenAlert(true);
      } else {
        await props.requestContactCovid({}, false);
      }
    }

  return (
    <>
      <Favicon url={FavIcon}/>
      <Navbar />
      {formStep === 5 && 
        <div className="hero">
          <div className="content-hero">
            <h2 className="title">Prueba digital de COVID19 de SEMM</h2>
          </div>
        </div>
      }
      <div className="covid-page container flex-col  flex-center jc-c">
        {formStep === 1 && 
          <StepOne handleCheckbox={handleChexkboxStep} handleFormStep={handleFormStep}/>
        }
        {formStep === 2 &&
          <StepTwo formErrors={formErrors} 
                   handleSelect={handleSelect} 
                   handleRadio={handleRadio} 
                   values={covidValues} 
                   handleFormStep={handleFormStep} 
                   disabledButton={disabledButton} />
        }
        {formStep === 3 && 
          <StepThree handleCheckbox={handleChexkboxStep} 
                     handleFormStep={handleFormStep}  />
        }
        {formStep === 4 && 
          <StepFour formErrors={formErrors} 
                    handleInput={handleInputGlobal}
                    values={covidValues}
                    handleCheckTerms={handleCheckTerms}
                    handleFormStep={handleFormStep} 
                    disabledButton={disabledButton} 
                    handleSubmit={handleSubmit} />
        }
        {formStep === 5 && 
          <TestResult  formErrors={formErrors}
                       handleInput={handleInputGlobal}
                       handlePhone={handlePhone}
                       handleSubmitContact={handleSendData}
                       handleFormStep={handleFormStep} 
                       disabledButton={disabledButton} 
                       results={{...props.covidResponse, cell_phone: covidValues.cell_phone}}/>
        }
      </div>
      <SnackBar openAlert={openAlert} 
                messageAlert={props.errors.message ? props.errors.message : props.covidResponse.messageAlert} 
                handleClose={() => setOpenAlert(!openAlert)} />
      {props.loader.loading && 
        <Spinner />
      }
    </>
  );
};

function mapStateToProps(state) {
  return {
    covidResponse: state.covid19Control,
    errors: state.errorsControl,
    loader: state.loadingControl,
  };
}

export default connect(mapStateToProps, { requestCovid, requestContactCovid })(Covid19Page);