import React, { useEffect, useState } from 'react';
import { FormControl, RadioGroup, Radio, FormControlLabel, Checkbox } from '@material-ui/core'
import variables from '../../../theme/_variables.scss';
import { withStyles } from '@material-ui/core/styles';
import Button from './ButtoPrimary';

const styles = themes => ({
    root: {
        color: `${variables.primaryColor} !important`,
        '&$checked': {
            color: `${variables.primaryColor} !important`,
        },
    },
    checked: {},
});


const StepTwo = ({handleRadio, handleSelect, classes, handleFormStep, values, disabledButton }) => {
    const [ disabled, setDisabled ] = useState(disabledButton);

    useEffect(() => {
        window.scrollTo(0, 0);
        if(values.traveled === '' || !values.contact_infected) {
            setDisabled(true);
        } else if(disabledButton) {
            setDisabled(disabledButton)
        } else {
            setDisabled(false);
        }

    }, [values, disabledButton]);

    return (
        <>
            <div className="header-form flex-center flex-col">
                <span className="sub-title-covid font-16 text-center">
                    ¿Tienes síntomas de COVID-19?
                </span>
                <h2 className="title-covid font-30 text-center">
                    Cuéntanos tu situación de salud y te recomendarémos qué hacer
                </h2>
            </div>
            <div className="flex flex-col content-form">
                <span className="label-covid font-16  m-t10">
                ¿Has viajado fuera del país en los últimos 15 días?
                </span>
                <div className="font-16 flex jc-b">
                    <span className="m-b1">
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="traveled"
                                        name="traveled"
                                        row
                                        onChange={(e) => handleRadio(e, 'traveled')}
                                        className="color-gray m-l40 flex-center flex-row jc-b"
                            >
                                <FormControlLabel 
                                    value="Si"
                                    control={<Radio classes={{
                                        root: classes.root,
                                        checked: classes.checked,
                                    }}/>} 
                                    label="Si"
                                    labelPlacement="start"
                                />
                                <FormControlLabel 
                                    value="No"
                                    control={<Radio classes={{
                                        root: classes.root,
                                        checked: classes.checked,
                                    }}/>} 
                                    label="No"
                                    labelPlacement="start"
                                />
                            </RadioGroup>
                        </FormControl>
                    </span>
                </div>
                <span className="label-covid font-16 m-t60">
                    ¿Has tenido contacto con alguna persona infectada en los últimos 15 días?
                </span>
                <div className="font-16 flex jc-b m-b30">
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="contact_infected"
                                    name="contact_infected"
                                    value={values.contact_infected}
                                    className="color-gray"
                                    onChange={(e) => handleRadio(e, 'contact_infected')}
                        >
                            <FormControlLabel 
                                value="Directo" 
                                control={<Radio classes={{
                                    root: classes.root,
                                    checked: classes.checked,
                                }}/>} 
                                label={<span className="f-11em">Directo (he estado con alguien infectado)</span>}
                            />
                            <FormControlLabel 
                                value="Indirecto" 
                                control={<Radio classes={{
                                    root: classes.root,
                                    checked: classes.checked,
                                }}/>} 
                                label="Indirecto (he estado con alguien que ha estado con una persona infectada)"
                            />
                            <FormControlLabel 
                                value="No" 
                                control={<Radio classes={{
                                    root: classes.root,
                                    checked: classes.checked,
                                }}/>} 
                                label="No"
                            />
                            <FormControlLabel 
                                value="No lo se" 
                                control={<Radio classes={{
                                    root: classes.root,
                                    checked: classes.checked,
                                }}/>} 
                                label="No lo se"
                            />
                        </RadioGroup>
                    </FormControl>
                </div>
                <FormControlLabel control={
                    <Checkbox value={String(values.works_in_health)}
                            onChange={(e) => handleSelect(values.works_in_health === true ? false : true, 'works_in_health')}
                            classes={{
                                root: classes.root,
                                checked: classes.checked,
                            }}
                    />
                    } 
                    label={<span className="color-gray font-16">¿Es trabajador sanitario? (Labora en un centro de salud)</span>} 
                />
         
            </div>
            <div className="flex-center">
                <Button disabled={disabled} label="Siguiente" handleButton={ () => handleFormStep(3)}/>
            </div>
        </>
    )
};

export default withStyles(styles)(StepTwo);