import axios from "axios";
const serverUrl = process.env.REACT_APP_SERVER_URL;



export function apiCall(method, path, data, formData=false) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common["Authorization"] = `Bearer`;
    axios.defaults.baseURL = serverUrl;
    if(formData){
      axios.defaults.headers.common["Content-Type"] = 'multipart/form-data';
    }
    return axios[method.toLowerCase()](path, data)
      .then(res => {
        return resolve(res);
      })
      .catch(err => {
        //err.response.data === error details
        return reject(err.response);
      });
  });
}
