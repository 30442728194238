import Api  from "../../services/common";
import { COVID_19, CONTACT_COVID19 } from "../actionTypes";
import { reset as setLoading } from "./loading";
import { error as setError,  } from "./errors";


export const sendCovidValues = (data, ACTION) => ({
  type: ACTION,
  payload: { ...data }
});


export const requestCovid = (data) => {
  return async dispatch => {
      try {
          dispatch(setLoading(true));
          const covidValues = await Api.covid19(data);
          dispatch(sendCovidValues(covidValues.data, COVID_19));
          dispatch(setLoading(false));
          dispatch(setError());
      } catch (e) {
        dispatch(setError(e.data ? e.data.error.message : 'Hubo un error para enviar sus datos, intente nuevamente'));
        dispatch(setLoading(false));
      }
  }
}

export const requestContactCovid = (data, validated=true) => {
    return async dispatch => {
        try {
          dispatch(setLoading(true));
          if(validated) {
            const contactValues = await Api.health_contact(data);
            dispatch(sendCovidValues({
                ...contactValues, 
                successContact: true,
                messageAlert: 'Su datos han sido enviados exitosamente.'
            }, CONTACT_COVID19));
            dispatch(setError());
          } else {
            dispatch(sendCovidValues({
              successContact: false,
              messageAlert: 'Verifique los errores y rellene los campos requeridos.'
            }, CONTACT_COVID19));
          }
          dispatch(setLoading(false));
        } catch (e) {
          dispatch(setError(e.data ? e.data.error.message : 'Hubo un error para enviar sus datos, intente nuevamente'));
          dispatch(setLoading(false));
        }
    }
  }
  

